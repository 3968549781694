<template>
    <div>
        <div class="page-title">会员列表</div>

        <div class="search-tab">
            <at-input v-model="query.keywords" class="mb-4" width="500px" placeholder="按用户id，昵称搜索">
                <button @click="getFirstList" class="btn">搜索</button>
            </at-input>

        </div>

        <a-table :columns="table.columns"
                 :rowKey="record => record.id || record.key"
                 :data-source="table.data"
                 :pagination="table.pagination"
                 @change="tableChanged"
                 :locale="{emptyText:'暂无会员'}"
                 class="members-table"
                 :loading="table.loading"
        >
            <div slot="no" slot-scope="id,item,idx">
                {{ idx + 1 }}
            </div>

            <div slot-scope="name,member" slot="name">
                <router-link class="member-name" :to="`/ast/members/${member.id}`">{{ name }}</router-link>
            </div>

            <div class="avatar" slot="avatar" slot-scope="avatar">
                <template v-if="avatar">
                    <img :src="avatar" alt="">
                </template>
                <template v-else>
                    <img src="/assets/icons/dfava.png" alt="">
                </template>
            </div>

            <div slot="level_name" slot-scope="levelName,item">
                <a class="level-name" @click="activeLevelModal(item)">
                    <template v-if="item.level_id > 1">
                        <div class="level-card">
                            {{ levelName }}
                        </div>
                    </template>
                    <template v-else>游客</template>
                </a>
            </div>
            <div slot="level_expired_info" slot-scope="days,item">
                <a class="level-name" @click="activeLevelModal(item)">
                    {{ days }}
                </a>
            </div>


            <div slot="def" slot-scope="val">{{ val || ' - ' }}</div>

            <div slot="mini_code" title="推广小程序码" slot-scope="val,item">
                <a @click="activeMiniCode(item)">
                    <a-icon type="qrcode"/>
                </a>
            </div>
        </a-table>

        <a-modal v-model="showLevelModal" @ok="confirmUpdateLevel">
            <div slot="title">
                <span>{{ selectedMember.mark_name || selectedMember.name }}</span>
                <small>[{{ selectedMember.member_id }}]</small>
            </div>

            <div>
                <div class="sd-item item-two">

                    <div class="sd-item-sg" style="width: 150px">

                        <div class="sd-item-title">变更方式</div>

                        <div class="sd-item-content">

                            <a-select v-model="levelInfo.method" class="full-with at-select">
                                <a-select-option value="change">修改</a-select-option>
                                <a-select-option value="renew">续期</a-select-option>
                            </a-select>

                        </div>
                    </div>


                    <div class="sd-item-sg">

                        <div class="sd-item-title">会员等级</div>

                        <div class="sd-item-content">

                            <a-select v-model="levelInfo.id" class="full-with at-select">
                                <template v-for="(level,key) in memberLevels">
                                    <a-select-option :value="level.id" :key="key">
                                        <span>{{ level.name }}</span>
                                    </a-select-option>
                                </template>
                            </a-select>

                        </div>
                    </div>

                    <div class="sd-item-sg">

                        <div class="sd-item-title">会员有效期限</div>

                        <div class="sd-item-content">

                            <a-select v-model="levelInfo.days" class="full-with at-select">
                                <template v-for="(md,key) in memberLevelDays">
                                    <a-select-option :value="md.day" :key="key">
                                        <span>{{ md.name }}</span>
                                    </a-select-option>
                                </template>
                            </a-select>

                        </div>
                    </div>
                </div>
            </div>

        </a-modal>

        <img v-if="miniCodes.detect" @load="qrLoad" class="preload-img" :src="miniCodes.detect" alt="">
        <img v-if="miniCodes.style" @load="qrLoad" class="preload-img" :src="miniCodes.style" alt="">

        <a-modal v-model="showMiniCodeModal" title="推广码" :footer="null">
            <div class="mini-qrcode-container">
                <div>
                    <small>(AI在线检测)</small>
                    <br>
                    <img v-if="miniCodes.detect" :src="miniCodes.detect" alt="">
                    <br>
                    <a class="btn" :href="miniCodes.detect">下载</a>
                </div>
                <div>
                    <small>(风格报告检测)</small>
                    <br>
                    <img v-if="miniCodes.style" :src="miniCodes.style" alt="">
                    <br>
                    <a class="btn" :href="miniCodes.style">下载</a>
                </div>
            </div>
        </a-modal>

    </div>
</template>

<script>

import api from "../../../repo/api";
import AtInput from "@/components/AtInput.vue";
import axios from "axios";
import auth from "@/auth";

export default {
    components: {AtInput},
    data() {
        return {
            showMiniCodeModal: false,
            miniCodes: {
                detect: '',
                style: '',
                ready: 0,
            },
            detailId: 0,
            memberLevels: [],
            query: {
                status: 1
            },
            table: {
                columns: [
                    {
                        title: '序号',
                        dataIndex: 'no',
                        width: '80px',
                        scopedSlots: {customRender: 'no'},
                    },
                    {
                        title: 'ID',
                        dataIndex: 'member_id',
                        width: '110px',
                    },
                    {
                        title: '头像',
                        dataIndex: 'avatar',
                        scopedSlots: {customRender: 'avatar'},
                    },
                    {
                        title: '昵称',
                        dataIndex: 'name',
                        scopedSlots: {customRender: 'name'},
                    }, {
                        title: '备注名',
                        dataIndex: 'mark_name',
                        scopedSlots: {customRender: 'def'},
                    }, {
                        title: '推广码',
                        dataIndex: 'mini_code',
                        scopedSlots: {customRender: 'mini_code'},
                    },
                    {
                        title: '会员等级',
                        dataIndex: 'level_name',
                        scopedSlots: {customRender: 'level_name'},
                    },
                    {
                        title: '会员有效期',
                        dataIndex: 'level_expired_info',
                        scopedSlots: {customRender: 'level_expired_info'},
                    },
                    {
                        title: '城市',
                        dataIndex: 'city',
                        scopedSlots: {customRender: 'def'},
                    },
                    {
                        title: '联系电话',
                        dataIndex: 'phone',
                        scopedSlots: {customRender: 'def'},
                    }
                ],
                loading: true,
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                    showTotal: (total, range) => {
                        return `当前${range[0]}-${range[1]}，共 ${total} 个会员`;
                    },
                },
                data: []
            },

            selectedMember: {},
            showLevelModal: false,
            levelInfo: {
                id: "",
                days: "",
                am_id: "",
                method: "change",
            },
            memberLevelDays: [
                {
                    name: "长期（无期限）",
                    day: -1
                },
                {
                    name: "1年（365天）",
                    day: 365
                },
                {
                    name: "半年（180天）",
                    day: 180
                },
                {
                    name: "3个月（90天）",
                    day: 90
                },
                {
                    name: "1个月（30天）",
                    day: 30
                },
                {
                    name: "半个月（15天）",
                    day: 15,
                },
                {
                    name: "一星期（7天）",
                    day: 7
                }, {
                    name: "已过期",
                    day: 0
                },
            ],
        }
    },
    mounted() {
        this.getFirstList()
    },
    methods: {
        activeMiniCode(item) {

            this.miniCodes.detect = ''
            this.miniCodes.style = ''

            this.miniCodes.ready = 2;
            this.$loading.show()

            setTimeout(() => {

                this.miniCodes.detect = this.buildUrl(
                    "packagesF/detectIndex/detectIndex?ref=" + item.id,
                    'AI在线检测推广码ID' + item.id
                );
                this.miniCodes.style = this.buildUrl(
                    "packagesE/detectStyle/detectStyle?ref=" + item.id,
                    '风格报告检测推广码ID' + item.id
                );

            },50)
        },
        qrLoad() {
            this.miniCodes.ready--;

            if (this.miniCodes.ready <= 0) {
                this.$loading.hide()
                this.showMiniCodeModal = true
            }
        },
        buildUrl(path, name) {

            let baseUrl = axios.defaults.baseURL + '/collocation/com/mini-qrcode?path=';

            baseUrl += encodeURI(path);
            baseUrl += '&token=' + auth.getToken()
            baseUrl += '&name=' + name;
            baseUrl += '&_mini_app_id=' + auth.appId()

            baseUrl = encodeURI(baseUrl);

            console.log(baseUrl);

            return baseUrl;
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        activeLevelModal(member) {

            this.selectedMember = JSON.parse(JSON.stringify(member))
            this.levelInfo.id = this.selectedMember.level_id
            this.levelInfo.am_id = this.selectedMember.id

            if (!this.memberLevels.length) {

                return this.getLevels(() => {
                    this.showLevelModal = true
                })
            }

            this.showLevelModal = true
        },
        confirmUpdateLevel() {

            this.$loading.show()

            api.post('/ast-member/update-level', this.levelInfo, (data) => {

                this.$loading.hide()

                this.$message.data(data)

                if (data.code === 0) {
                    this.showLevelModal = false
                    this.getList()
                }
            })
        },
        getList() {

            this.table.loading = true

            api.get('/ast-member/members', this.query, (data) => {
                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.table.loading = false

            })
        },
        getLevels(success) {
            this.$loading.show()

            api.get('/ast-app/member-levels', {all: 1}, (data) => {

                this.memberLevels = data.data.length ? data.data : []

                this.$loading.hide()

                if (success) success()
            });
        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        }
    }
}
</script>
<style lang="less" scoped>
.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
    }
}

.level-card {
    border-radius: 8px;
    border: 1px solid #EDC483;
    color: #EDC483;
    text-align: center;
    display: inline-block;
    padding: 2px 6px;
}

.level-name {
    cursor: pointer;
}

.members-table /deep/ table {
    .ant-table-thead > tr > th {
        background: transparent;
        padding: 12px 12px;
    }

    .ant-table-column-title {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
    }

    .ant-table-tbody > tr > td {
        font-size: 14px;
        font-weight: 400;
        color: #606266;
        line-height: 14px;
        padding: 12px 12px;
    }
}

.search-tab {
    display: flex;
    justify-content: space-between;
}

.sd-item {
    margin-top: 32px;

    &:first-child {
        margin-top: 0;
    }
}

.sd-item-title {
    margin-bottom: 16px;
}

.item-two {
    display: flex;
    justify-content: left;

    .sd-item-sg {
        width: 50%;

        &:first-child {
            padding-right: 20px;
        }

        &:last-child {
            padding-left: 20px;
        }
    }
}

.mini-qrcode-container {
    display: flex;
    text-align: center;

    > div {
        width: 50%;
    }

    img {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 80%;
    }

    a:hover {
        color: white;
    }
}
</style>